// Generated by Framer (11696e7)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./k8fm8aByf-0.js";

const enabledGestures = {oWG9EmiHO: {hover: true}};

const cycleOrder = ["oWG9EmiHO"];

const serializationHash = "framer-ozTgX"

const variantClassNames = {oWG9EmiHO: "framer-v-jp6kkm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {bCVeFfDXK: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {delay: 0, duration: 0.1, ease: [0.5, 0, 0.88, 0.77], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oWG9EmiHO", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-jp6kkm", className, classNames)} data-framer-name={"Circle Full"} layoutDependency={layoutDependency} layoutId={"oWG9EmiHO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9bc115a6-d199-4eab-b2c8-8f4dcf74cb53, rgb(255, 254, 254))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} variants={{"oWG9EmiHO-hover": {backgroundColor: "var(--token-120ba472-3cd2-4e88-8e4a-2a480b748839, rgb(247, 163, 26))"}}} {...addPropertyOverrides({"oWG9EmiHO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ozTgX.framer-u4sat4, .framer-ozTgX .framer-u4sat4 { display: block; }", ".framer-ozTgX.framer-jp6kkm { cursor: pointer; height: 142px; position: relative; width: 142px; }", ".framer-ozTgX.framer-v-jp6kkm.hover.framer-jp6kkm { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 142px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 142
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"e_mZCWMZ9":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerk8fm8aByf: React.ComponentType<Props> = withCSS(Component, css, "framer-ozTgX") as typeof Component;
export default Framerk8fm8aByf;

Framerk8fm8aByf.displayName = "Circle-Full";

Framerk8fm8aByf.defaultProps = {height: 142, width: 142};

addFonts(Framerk8fm8aByf, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})